import data from "web/data.json";
import { useExperiment } from "web/state-containers/experiment";

export function access(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export function accessVariant(at, variant) {
  const accessed = access(data, at);
  if ("string" === typeof accessed) return accessed;

  return accessed[variant] || accessed[0] || accessed;
}

export const Text = ({ at, ...props }) => {
  const { variant } = useExperiment();
  const accessedVariant = accessVariant(at, variant);

  if (!accessedVariant) {
    console.error(`Text node at ${at} not found`);
    return null;
  }

  if (accessedVariant?.html)
    return (
      <span
        dangerouslySetInnerHTML={{ __html: accessedVariant?.html }}
        {...props}
      />
    );

  return accessedVariant;
};
