"use client";
import { motion } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { captureEvent } from "ui/lib/captureEvent";
import { updateUTMParams } from "ui/lib/misc";
import { cn } from "ui/lib/utils";
import { accessVariant } from "web/components/text-node";
import { useExperiment } from "web/state-containers/experiment";

const MotionLink = motion(Link);

interface LinkActionProps extends React.ComponentPropsWithoutRef<typeof Link> {
  event: {
    category?: string;
    action: string;
    [key: string]: any;
  };
  href: string;
  children: React.ReactNode;
}

export const LinkAction: React.FC<LinkActionProps> = ({
  children,
  event,
  href,
  ...props
}) => {
  const handleClick = () => {
    captureEvent({ ...event, label: children });
  };

  return (
    <Link href={href} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

interface ActionProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MotionLink>, "href"> {
  at: string;
  defaultClasses?: string;
  className?: string;
  event?: {
    category?: string;
    action?: string;
    label?: string;
    [key: string]: any;
  };
}

export const Action = React.memo<ActionProps>(
  ({
    at,
    defaultClasses = "text-lg rounded-full cta",
    className,
    event,
    ...props
  }) => {
    const { variant } = useExperiment();
    const accessedVariant = accessVariant(at, variant as string);
    const [href, setHref] = useState<string>(
      accessedVariant.href || accessedVariant.link || ""
    );

    const sendEvent = () => {
      captureEvent({
        category: at,
        action: "find_my_plan_cta_clicked",
        label: accessedVariant.label,
        ...event,
      });
    };

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      sendEvent();
      props.onClick?.(e);
    };

    useEffect(() => {
      setHref((initial) => {
        if (accessedVariant.link) {
          return accessedVariant.link;
        }
        if (accessedVariant.href) {
          return updateUTMParams(accessedVariant.href).href;
        }
        return initial;
      });
    }, [accessedVariant]);

    if (!href) {
      console.log({ at, variant, accessedVariant });
      return null;
    }

    return (
      <MotionLink
        {...props}
        key={href}
        href={href}
        target={
          accessedVariant.target
            ? accessedVariant.target
            : accessedVariant.href
              ? "_self"
              : undefined
        }
        rel="noreferrer"
        onClick={handleClick}
        aria-label={accessedVariant.label}
        className={cn(
          "relative inline-block cursor-pointer",
          defaultClasses,
          className
        )}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.975 }}
      >
        {accessedVariant.label}
      </MotionLink>
    );
  }
);

Action.displayName = "Action";
